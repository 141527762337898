import React from "react"

const ContentComponent = () => {
    return(
        <div class="container my-24 px-6 mx-auto">
        <section class="mb-32">

        <div class="flex flex-wrap">
          <div class="grow-0 shrink-0 basis-auto w-full lg:w-5/12 mb-12 lg:mb-0">
            <div class="flex lg:py-12">
              <img src={require('../images/(2).jpg')} class="w-full rounded-lg shadow-lg"
                id="cta-img-nml-50" alt="" />
            </div>
          </div>
    
          <div class="grow-0 shrink-0 basis-auto w-full lg:w-7/12">
            <div
              class="bg-yellow-500 h-full rounded-lg p-6 lg:pl-12 text-white flex items-center text-center lg:text-left">
              <div class="lg:pl-12">
                <h2 class="text-3xl font-bold mb-6">Tentang kami</h2>
                <p class="mb-6 pb-2 lg:pb-0">
                CV. BHAKTI KARYA MULYA merupakan perusahaan yang bergerak dibidang Pekerjaan Pengadaan Barang dan Jasa, berkantor dan berkedudukan di Jalan Agil Kusumadya No. 111 Jati Wetan Gang Abimanyu, Kabupaten Kudus. 
                </p>
    
                <div class="flex flex-col md:flex-row md:justify-around xl:justify-start mb-6 mx-auto">
                  <p class="flex items-center mb-4 md:mb-2 lg:mb-0 mx-auto md:mx-0 xl:mr-20">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 mr-2">
                      <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                      </path>
                    </svg>
                    Best team
                  </p>
    
                  <p class="flex items-center mb-4 md:mb-2 lg:mb-0 mx-auto md:mx-0 xl:mr-20">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 mr-2">
                      <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                      </path>
                    </svg>
                    Best quality
                  </p>
    
                  <p class="flex items-center mb-2 lg:mb-0 mx-auto md:mx-0">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="w-4 h-4 mr-2">
                      <path fill="currentColor"
                        d="M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z">
                      </path>
                    </svg>
                    Best experience
                  </p>
                </div>
    
                <p>
                CV. BHAKTI KARYA MULYA didirikan pada tanggal 23 April 2011 oleh Notaris H. Djoko Sanjoto, SH hingga saat ini Kami selalu mendapatkan kepercayaan dari banyak rekanan dan instansi pemerintah maupun swasta, untuk mengerjakan berbagai pesanan barang sesuai kebutuhan.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    )
}

export default ContentComponent