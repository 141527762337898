import logo from './logo.svg';
import './App.css';
import NavBarComponent from './components/NavbarComponent';


function App() {

  return (
    <div className="App">
      <NavBarComponent/>

    </div>
  );
}

export default App;
