import React from "react"

const FeatureComponent = () => {
    return(
        <div class="container my-24 px-6 mx-auto">
        
        <section class="mb-32 text-gray-800 text-center">
            <h2 class="text-3xl font-bold mb-20">Visi</h2>

            <div class="grid lg:gap-x-12 lg:grid-cols-1">
            <div class="mb-12 lg:mb-0">
                <div class="rounded-lg shadow-lg h-full block bg-white">
                <div class="flex justify-center">
                    <div class="p-4 bg-blue-600 rounded-full shadow-lg inline-block -mt-8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-8 h-8 text-white" viewBox="0 0 16 16">
                        <path d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935z"/>
                    </svg>
                    </div>
                </div>
                <div class="p-6">
                    <p>
                    Menjadi perusahaan rekanan yang terpercaya, komitmen, dan konsisten dalam bidangnya. 
                    </p>
                </div>
                </div>
            </div>


            </div>
        </section>

        <section class="mb-32 text-gray-800 text-center">
            <h2 class="text-3xl font-bold mb-20">Misi</h2>

            <div class="grid lg:gap-x-12 lg:grid-cols-4">
            <div class="mb-12 lg:mb-0">
                <div class="rounded-lg shadow-lg h-full block bg-white">
                <div class="flex justify-center">
                    <div class="p-4 bg-blue-600 rounded-full shadow-lg inline-block -mt-8">
                    <svg class="w-8 h-8 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                        <path fill="currentColor"
                        d="M192 208c0-17.67-14.33-32-32-32h-16c-35.35 0-64 28.65-64 64v48c0 35.35 28.65 64 64 64h16c17.67 0 32-14.33 32-32V208zm176 144c35.35 0 64-28.65 64-64v-48c0-35.35-28.65-64-64-64h-16c-17.67 0-32 14.33-32 32v112c0 17.67 14.33 32 32 32h16zM256 0C113.18 0 4.58 118.83 0 256v16c0 8.84 7.16 16 16 16h16c8.84 0 16-7.16 16-16v-16c0-114.69 93.31-208 208-208s208 93.31 208 208h-.12c.08 2.43.12 165.72.12 165.72 0 23.35-18.93 42.28-42.28 42.28H320c0-26.51-21.49-48-48-48h-32c-26.51 0-48 21.49-48 48s21.49 48 48 48h181.72c49.86 0 90.28-40.42 90.28-90.28V256C507.42 118.83 398.82 0 256 0z">
                        </path>
                    </svg>
                    </div>
                </div>
                <div class="p-6">
                    <h5 class="text-lg font-semibold mb-4">Support Penuh</h5>
                    <p>
                    Memberikan pelayanan terbaik pada konsumen 
                    </p>
                </div>
                </div>
            </div>
            <div class="mb-12 lg:mb-0">
                <div class="rounded-lg shadow-lg h-full block bg-white">
                <div class="flex justify-center">
                    <div class="p-4 bg-blue-600 rounded-full shadow-lg inline-block -mt-8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-8 h-8 text-white" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"/>
                    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                    </svg>
                    </div>
                </div>
                <div class="p-6">
                    <h5 class="text-lg font-semibold mb-4">Professional</h5>
                    <p>
                    Menyediakan tenaga yang profesional di bidangnya 
                    </p>
                </div>
                </div>
            </div>

            <div class="mb-12 lg:mb-0">
                <div class="rounded-lg shadow-lg h-full block bg-white">
                <div class="flex justify-center">
                    <div class="p-4 bg-blue-600 rounded-full shadow-lg inline-block -mt-8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-8 h-8 text-white" viewBox="0 0 16 16">
                    <path d="M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z"/>
                    </svg>
                    </div>
                </div>
                <div class="p-6">
                    <h5 class="text-lg font-semibold mb-4">Berkualitas</h5>
                    <p>
                    Menghasilkan produk yang berkualitas 
                    </p>
                </div>
                </div>
            </div>

            <div class="">
                <div class="rounded-lg shadow-lg h-full block bg-white">
                <div class="flex justify-center">
                    <div class="p-4 bg-blue-600 rounded-full shadow-lg inline-block -mt-8">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="w-8 h-8 text-white" viewBox="0 0 16 16">
                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"/>
                        <path fill-rule="evenodd" d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"/>
                        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"/>
                    </svg>
                    </div>
                </div>
                <div class="p-6">
                    <h5 class="text-lg font-semibold mb-4">Turut Berkontribusi</h5>
                    <p>
                    Membantu program instansi pemerintah dan swasta berkontribusi dalam menjaga hubungan sosial, kebersihan, dan ketahanan pangan bermasyarakat.
                    </p>
                </div>
                </div>
            </div>
            </div>
        </section>
        </div>

    )
}

export default FeatureComponent