import React from "react"
import { useRef,forwardRef } from "react"
import ContentComponent from './ContentComponent';
import FeatureComponent from './FeatureComponents';
import TestimonialComponent from './TestimonialComponent';
import ContactComponent from './ContactComponent';
import FooterComponent from './FooterComponent';
import GalleryComponent from './GalleryComponent';

const NavBarComponent = () => {
    const myRefContact = useRef(null)
    const myRefContent = useRef(null)
    const myRefGallery = useRef(null)
    const myRefTestimonial = useRef(null)
 
 
    const executeScrollContact = () => myRefContact.current.scrollIntoView({ behavior: 'smooth' })  
    const executeScrollContent = () => myRefContent.current.scrollIntoView({ behavior: 'smooth' })  
    const executeScrollGallery = () => myRefGallery.current.scrollIntoView({ behavior: 'smooth' })  
    const executeScrollTestimonial = () => myRefTestimonial.current.scrollIntoView({ behavior: 'smooth' })  

    return(
      <div>
<section class="mb-40">
    <nav class="navbar navbar-expand-lg shadow-md py-2 bg-white relative flex items-center w-full justify-between">
      <div class="px-6 w-full flex flex-wrap items-center justify-between">
        <div class="flex items-center">
          <button
            class="navbar-toggler border-0 py-3 lg:hidden leading-none text-xl bg-transparent text-gray-600 hover:text-gray-700 focus:text-gray-700 transition-shadow duration-150 ease-in-out mr-2"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContentY"
            aria-controls="navbarSupportedContentY"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              class="w-5"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
              ></path>
            </svg>
          </button>
          <div class="navbar-brand text-blue-600" href="#!">
            <img
              src={require('../images/Logo.jpg')}
              class="w-8 h-8 ml-2 lg:ml-0 mr-2"
              alt=""
            />
          </div>
          <div class="nav-item">
              <h4 class=" text-2xl block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light">Bhakti Karya</h4>
            </div>
        </div>
        <div class="navbar-collapse collapse grow items-center" id="navbarSupportedContentY">
          <ul class="navbar-nav mr-auto lg:flex lg:flex-row">
            
            <li class="nav-item">
              <a class="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light" onClick={executeScrollContent}>Tentang Kami</a>
            </li>
            <li class="nav-item mb-2 lg:mb-0">
              <a class="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light" button onClick={executeScrollContact}>Kontak</a>
            </li>
            <li class="nav-item mb-2 lg:mb-0">
              <a class="nav-link block pr-2 lg:px-2 py-2 text-gray-600 hover:text-gray-700 focus:text-gray-700 transition duration-150 ease-in-out" href="#!" data-mdb-ripple="true" data-mdb-ripple-color="light" button onClick={executeScrollTestimonial}>Pengalaman Kami</a>
            </li>
          </ul>
        </div>
        <div class="flex items-center lg:ml-auto">
        </div>
      </div>
    </nav>

    <div class="px-6 py-12 md:px-12 bg-gray-50 text-gray-800 text-center lg:text-left">
      <div class="container mx-auto xl:px-32">
        <div class="grid lg:grid-cols-2 gap-12 flex items-center">
          <div class="mt-12 lg:mt-0">
            <h1 class="text-5xl md:text-6xl xl:text-7xl font-bold tracking-tight mb-12">Yang terbaik <br /><span class="text-blue-600">untuk kebutuhan karoseri anda</span></h1>
            <a class="inline-block px-7 py-3 mr-2 bg-blue-600 text-white font-medium text-sm leading-snug uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="light" href="#!" role="button" onClick={executeScrollContact}>Hubungi Kami</a>
            <a class="inline-block px-7 py-3 bg-transparent text-blue-600 font-medium text-sm leading-snug uppercase rounded hover:text-blue-700 hover:bg-gray-100 focus:bg-gray-100 focus:outline-none focus:ring-0 active:bg-gray-200 transition duration-150 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="light" href="#!" role="button" onClick={executeScrollContent}>Pelajari lebih lanjut</a>
          </div>
          <div class="mb-12 lg:mb-0">
            <img
              src={require('../images/(2).jpg')}
              class="w-full rounded-lg shadow-lg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </section>
  <ContentComponent/>
      <div ref={myRefContent}>
      <FeatureComponent/>
      </div>
      <div ref={myRefGallery}>
      <GalleryComponent />
      </div>
      <div ref={myRefTestimonial}>
      <TestimonialComponent/>
      </div>
      <div ref={myRefContact}>
      <ContactComponent/>
      </div>
      
      <FooterComponent/>
  </div>
    )
}

export default NavBarComponent